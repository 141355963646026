export const isMobile = eval(process.env.VUE_APP_IS_MOBILE);

export const Role = {
  ROOT: 1,
  ADMIN: 2,
  USER: 3,
  ADMIN_PARCEIRO: eval(process.env.VUE_APP_IS_MOBILE) ? 5 : 4,
  USER_PARCEIRO: 5,
  COMISSARIO: 6,
};

export const RoleDefaultPath = {
  1: "/",
  2: "/",
  3: "/",
  4: "/listagem-produtos",
  5: "/check-in",
  6: "/comissarios"
};

export const appProduto = () => {
  return process.env.VUE_APP_PRODUTO ? process.env.VUE_APP_PRODUTO : "milgrau";
};

export const isInfluencers = () => {
  return appProduto() == "influencers";
};

export const isMilgrau = () => {
  return appProduto() == "milgrau";
};

export const isIngressoLivre = () => {
  return appProduto() == "ingressoLivre";
};

export const isConca = () => {
  return appProduto() == "conca";
};

export const isBelaEntrada = () => {
  return appProduto() == "belaentrada";
};

export const isSBEventos = () => {
  return appProduto() == "sbeventos";
};

export const isElfort = () => {
  return appProduto() == "elfort";
};

export const isPorter = () => {
  return appProduto() == "porter";
};

export const isPt = () => {
  return appProduto() == "pt";
};

export const isNxl = () => {
  return appProduto() == "nxl";
};



