let colors = {
  black: "#000000",
  white: "#ffffff",
  ice: "#eeeeee",
  accent: "#FFF0EB",
  error: "#ff1744",
  backgroundColor: "#f3f4f5"
};

let produtoColorsInfluencers = {
  primary: "#160425",
    secondary: "#B21D18",
    btnCancelar: "#160425",
    tertiary: "#B41171",
    loginForm: "#C21172",
    backLogin: "#1F0017"
};

let produtoColorsMilgrau = {
  primary: "#C11718",
    secondary: "#FFFFFF",
    btnCancelar: "#1E88E5",
    tertiary: "#FFF",
    loginForm: "#C11718",
    backLogin: "#f3f4f5"
};

let produtoColorIngressoLivre = {
  primary: "#008de4",
  secondary: "#010101",
  tertiary: "#727272",
  loginForm: "#fff",
  backLogin: "#e5e3e3"
};

let produtoColorsConca = {
  primary: "#1D1D1B",
  secondary: "#EE7D22",
  tertiary: "#FFF",
  loginForm: "#fff",
  backLogin: "#fff"
};

let produtoColorsBela = {
  primary: "#4B6CB7",
  secondary: "#00F36E",
  tertiary: "#FFF",
  loginForm: "#fff",
  backLogin: "#fff"
};

let produtoColorsSBEventos = {
  primary: "#681673",
  secondary: "#3A0C40",
  tertiary: "#F2D0DD",
  loginForm: "#A61458",
  backLogin: "#F21667"
};

let produtoColorsElfort = {
  primary: "#28FFD4",
  secondary: "#0E1618",
  tertiary: "#0D2A31",
  loginForm: "#fff",
  backLogin: "#fff"
};

let produtoColorsPorter = {
  primary: "#2646A9",
  secondary: "#696BD2",
  tertiary: "#FF882E",
  loginForm: "#fff",
  backLogin: "#fff"
};

let produtoColorsPt = {
  primary: "#000",
  secondary: "#fff",
  tertiary: "#000",
  loginForm: "#fff",
  backLogin: "#fff"
};

let produtoColorsNxl = {
  primary: "#404850",
  secondary: "#FF5D29",
  tertiary: "#fff",
  loginForm: "#fff",
  backLogin: "#fff"
};

export const colorsInfluencers = { ...colors, ...produtoColorsInfluencers };
export const colorsMilgrau = { ...colors, ...produtoColorsMilgrau };
export const colorsIngressoLivre = {...colors, ...produtoColorIngressoLivre};
export const colorsConca = {...colors, ...produtoColorsConca};
export const colorsBela = {...colors, ...produtoColorsBela};
export const colorsSBEventos = {...colors, ...produtoColorsSBEventos};
export const colorsElfort = {...colors, ...produtoColorsElfort};
export const colorsPorter = {...colors, ...produtoColorsPorter};
export const colorsPt = {...colors, ...produtoColorsPt};
export const colorsNxl = {...colors, ...produtoColorsNxl};