<template>
  <v-app id="inspire">
    <v-snackbar
      :color="$root.type"
      v-model="$root.alert"
      top
      right
      dark
      transition="scroll-x-reverse-transition"
    >
      <v-row>
        <v-icon color="white" class="ml-2 mr-2">
          {{ $root.icon }}
        </v-icon>
        <span v-html="$root.message"></span>
      </v-row>
    </v-snackbar>
    <install-modal
      :show="deferredPrompt"
      :install="install"
      :notInstall="dismiss"
    />

    <template>
      <template v-if="logado">
        <v-navigation-drawer
          v-if="!mobile"
          v-model="drawer"
          :mini-variant="mini"
          app
          clipped
          permanent
        >
          <v-list dense>
            <v-list-item
              v-for="(item, i) in acoesPermitidas(items)"
              :key="i"
              link
              :to="item.link"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-app-bar color="primary" clipped-left dense elevation="1" app>
          <v-app-bar-nav-icon
            class="ml-2"
            color="secondary"
            @click="mini = !mini"
          />
          <div>
            <v-img
              :contain="isInfluencers()"
              :src="logoPath"
              :width="isMilgrau() ? '230' : (isBelaEntrada() ? '80' : ( isPt() ? '200' : '120')) "               
              :height="isMilgrau() ? '35' : ( isPt() ? '45' :  ( isNxl() ? '18' : '35')) "               
            ></v-img>
          </div>
          <v-spacer></v-spacer>
          <v-row no-gutters>
            <v-col cols="12" :md="8 / vlControle"></v-col>
            <v-col cols="12" :md="4 * vlControle" v-if="showPesquisa">
              <v-autocomplete
                v-if="!mobile || isMobileView"
                v-model="item"
                :items="listaPost"
                :search-input.sync="search"
                color="primary"
                item-text="descricao"
                item-value="id"
                placeholder="Informe o texto de busca"
                prepend-inner-icon="mdi-magnify"
                @change="selectedPost"
                @click="callbackSearch"
                @blur="vlControle = 1"
                solo
                dense
                hide-details
                hide-selected
                clearable
                :loading="loadingSearch"
              >
                <template v-slot:item="data">
                  <div style="max-width: 40vw">
                    <span class="d-inline-block text-truncate">{{
                      data.item.descricao
                    }}</span>
                  </div>
                </template>
                <template slot="no-data">
                  <span class="ml-3 caption"
                    >Nenhum resultado encontrado, pesquise novamente</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <!-- V2
          <v-icon
            class="ml-4"
            color="secondary"
          >mdi-bell</v-icon>
          -->
          <!-- <v-icon
            class="ml-4"
            color="secondary"
            @click="openConfig"
            >mdi-cog</v-icon
          > -->
          <v-btn
            class="ml-4"
            text
            icon
            color="secondary"
            :to="'/config'"
            v-if="showConfig && !mobile"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>

          <v-menu v-model="menuAvatar" :nudge-width="200" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4" style="color: #fff" v-bind="attrs" v-on="on" icon>
                <avatar :avatarUrl="userData.fotoUrl" />
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ userData.nome }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userData.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item @click="openDialog">
                  <v-list-item-icon>
                    <v-icon >mdi-account-circle-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Perfil</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="logout" link>
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Sair</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-app-bar>

        <dialog-perfil-user
          v-if="dialog"
          :close-dialog="closeDialog"
          :usuario="userData"
          :callback="closeDialog"
          :disabledRole="true"
        />

        <dialog-post
          v-if="showDialogPost"
          :callback="closeDialogPost"
          :close-dialog="closeDialogPost"
          :post="post"
        />

        <!-- <dialog-config
          v-if="showDialogConfig"
          :callback="closeConfig"
          :close-dialog="closeConfig"
        /> -->

        <ConfirmDialog
          v-if="$root.confirmDialog"
          @close="closeConfirmDialog"
          @continuar="$root.callback($root.args)"
          :msg="$root.message"
          :title="$root.title"
        ></ConfirmDialog>

        <v-main>
          <router-view></router-view>
        </v-main>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
    </template>
  </v-app>
</template>

<script>
import avatar from "@/components/avatar.vue";
import DialogPerfilUser from "./components/dialog-perfil-user.vue";
// import DialogConfig from "./components/dialog-config.vue";
import { InstalacaoService, PostService } from "./services";
import DialogPost from "./views/post/dialog-post.vue";
import InstallModal from "./components/install-modal.vue";
import {  Role } from "./constants";
import ConfirmDialog from "./components/confirmDialog.vue";

export default {
  components: {
    avatar,
    DialogPerfilUser,
    DialogPost,
    InstallModal,
    // DialogConfig,
    ConfirmDialog,
  },

  data() {
    return {
      deferredPrompt: null,
      vlControle: 1,
      userData: {},
      logado: false,
      dialog: false,
      drawer: true,
      search: null,
      menuAvatar: false,
      mobile: eval(process.env.VUE_APP_IS_MOBILE),
      loading: true,
      timerId: null,
      listaPost: [],
      loadingSearch: false,
      showDialogPost: false,
      // showDialogConfig: false,
      post: null,
      item: null,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-home-outline",
          link: "/dashboard",
          roles: [Role.ROOT, Role.ADMIN, Role.USER],
        },
        {
          title: "Postagem",
          icon: "mdi-post-outline",
          link: "/posts",
          roles: [Role.ROOT, Role.ADMIN, Role.USER],
        },
        {
          title: "Mensagem", 
          icon: "mdi-email-outline",
          link: "/mensagens",
          roles: [Role.ROOT, Role.ADMIN],
        },
        {
          title: "Usuários",
          icon: "mdi-account-group",
          link: "/user/usuarios",
          roles: [Role.ROOT, Role.ADMIN_PARCEIRO],
        },
        {
          title: "Clientes",
          icon: "mdi-card-account-details-outline",
          link: "/client/clientes",
          roles: [Role.ROOT, Role.ADMIN],
        },
        {
          title: "Categorias",
          icon: "mdi-tag-outline",
          link: "/categoria/categorias",
          roles: [Role.ROOT, Role.ADMIN],
        },
        {
          title: "Usuários Externos",
          icon: "mdi-account-group-outline",
          link: "/usuario_externo/usuarios",
          roles: [Role.ROOT, Role.ADMIN],
        },
      ],
      mini: true,
      logoPath: "",
    };
  },
  computed: {
    isAdminParceiro() {
      return this.$store.getters["user/getUser"].roleId == Role.ADMIN_PARCEIRO;
    },
    isUserParceiro() {
      return this.$store.getters["user/getUser"].roleId == Role.USER_PARCEIRO;
    },
    isComissario() {
      return this.$store.getters["user/getUser"].roleId == Role.COMISSARIO;
    },
    isAdmin() {
      return this.$store.getters["user/getUser"].roleId == Role.ADMIN;
    },
    isRoot() {
      return this.$store.getters["user/getUser"].roleId == Role.ROOT;
    },
    showPesquisa() {
      return (
        !this.isAdminParceiro && !this.isUserParceiro && !this.isComissario
      );
    },
    isMobileView() {
      return window.innerWidth <= 750;
    },
    showConfig() {
      return this.isAdmin || this.isRoot;
    },
    habilitaMarketplace() {
      return this.$store.getters["instalacao/getInstalacao"]
        .habilitaMarketplace;
    },
    habilitaIngresso() {
      return this.$store.getters["instalacao/getInstalacao"]
        .habilitaIngresso;
    },
    habilitaPacote() {
      return this.$store.getters["instalacao/getInstalacao"].habilitaPacotes;
    },
    habilitaMobile() {
      return eval(process.env.VUE_APP_IS_MOBILE);
    },
  },
  watch: {
    search() {
      this.debouncePost();
    },
    habilitaMarketplace() {
      this.validaMarketPlace();
    },
    habilitaPacote() {
      this.validaPacote();
    },
    habilitaMobile() {
      this.validarMobile();
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  beforeMount() {
    this.logado = this.$store.getters["user/getAuthStatus"];
    if (this.logado) {
      this.userData = this.$store.getters["user/getUser"];

      InstalacaoService.getAtual().then((response) => {
        this.instalacao = response.data;
        this.$store.commit("instalacao/setInstalacao", this.instalacao);
      });
    }

    this.logoPath = require(`@/assets/${this.appProduto()}/logo.png`);
    this.validaPacote();
    this.validaMarketPlace();
  },
  methods: {
    validaPacote() {
      if (this.habilitaPacote) {
        this.items.push({
          title: "Pacote de Mídias",
          icon: "mdi-tooltip-image-outline",
          link: "/pacote-midias",
          roles: [Role.ROOT, Role.ADMIN],
        });
      }
    },
    validaMarketPlace() {
      if (this.habilitaMarketplace) {

        if(this.habilitaIngresso){
          this.items.push(
            {
              title: "Relatórios",
              icon: "mdi-file-sign",
              link: "/relatorios",
              roles: [Role.ROOT],
            },
            {
              title: "Eventos",
              icon: "mdi-calendar-star",
              link: "/evento",
              roles: [Role.ROOT, Role.ADMIN],
            },            
            {
              title: "Locais",
              icon: "mdi-table-headers-eye",
              link: "/locais",
              roles: [Role.ROOT, Role.ADMIN],
            }
          );
        }

        this.items.push(
          {
            title: "Marketplace",
            icon: "mdi-sack-percent",
            link: "/listagem-produtos",
            roles: [Role.ROOT, Role.ADMIN_PARCEIRO, Role.ADMIN],
          },
          {
            title: "Check-in",
            icon: "mdi-checkbox-marked-outline",
            link: "/check-in",
            roles: [Role.ROOT, Role.ADMIN_PARCEIRO, Role.USER_PARCEIRO, Role.ADMIN],
          },
          {
            title: "Minhas Vendas",
            icon: "mdi-ticket-account",
            link: "/comissarios",
            roles: [Role.COMISSARIO, Role.ADMIN],
          }
        );
      }
    },
    validarMobile() {
      if (this.isMobile) {
        this.items.push({
          title: "Política de Privacidade",
          icon: "mdi-account-card-outline",
          link: "/privacidade",
          roles: [Role.ROOT, Role.ADMIN_PARCEIRO, Role.USER_PARCEIRO],
        });
      }
    },
    logout() {
      this.$store.commit("user/setTokenAuth", "");
      this.$store.commit("user/setAuthStatus", false);
      window.location.reload();
    },
    acoesPermitidas(lista) {
      if (this.userData && this.userData.roleLabel) {
        return lista.filter((a) => {
          return a.roles.includes(this.userData.roleId);
        });
      }
      return [];
    },
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    debouncePost() {
      clearTimeout(this.timerId);
      if (this.search) {
        this.timerId = setTimeout(this.callbackSearch, 1000);
      } else {
        this.callbackSearch();
      }
    },
    callbackSearch() {
      this.vlControle = 2;
      this.loadingSearch = true;
      PostService.search(this.search)
        .then((resp) => {
          this.listaPost = resp.data;
        })
        .catch((err) => {
          window.console.error(err);
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    },
    selectedPost(postId) {
      if (!postId) return;

      this.loading = true;
      this.item = null;
      this.search = null;
      PostService.getPostById(postId)
        .then((response) => {
          this.post = response.data;
          this.post.dhInicioPublicacao = new Date(this.post.dhInicioPublicacao);
          this.post.dhFimPublicacao = new Date(this.post.dhFimPublicacao);
          this.showDialogPost = true;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialogPost() {
      this.showDialogPost = false;
      this.post = null;
    },
    // openConfig() {
    //   this.showDialogConfig = true;
    // },
    // closeConfig() {
    //   this.showDialogConfig = false;
    // },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    closeConfirmDialog() {
      this.$root.confirmDialog = false;
      this.$root.message = "";
      this.$root.title = "";
    },
  },
};
</script>

<style>
.conteudo {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 20px;
}

.colorcliente {
  color: white;
}

@import "./styles/index.scss";
</style>
