import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import DatetimePicker from "vuetify-datetime-picker";
import VueTheMask from "vue-the-mask";
import "./plugins/vuetify-money.js";
import { colorsInfluencers, colorsMilgrau, colorsIngressoLivre, colorsConca, colorsBela, colorsSBEventos, colorsElfort, 
          colorsPorter, colorsPt, colorsNxl } from "./styles/colors";
import { appProduto, isInfluencers, isMilgrau, isIngressoLivre, isConca, isBelaEntrada, isSBEventos, isElfort,
          isPorter, isPt, isNxl } from "./constants";

const moment = require("moment");
const vueUpload = require('vue-upload-component');

moment.updateLocale("pt", {
  relativeTime: {
    future: "em %s",
    past: "%s atrás",
    s: "um segundo",
    ss: "%d segundos",
    m: "um minuto",
    mm: "%d minutos",
    h: "uma hora",
    hh: "%d horas",
    d: "um dia",
    dd: "%d dias",
    w: "uma semana",
    ww: "%d semanas",
    M: "um mês",
    MM: "%d meses",
    y: "um ano",
    yy: "%d anos"
  }
});

const sufixo = process.env.VUE_APP_URL_SUFFIX

var URL_BASE =
  process.env.NODE_ENV === "production"
    ? `https://back${process.env.VUE_APP_URL_PREFIX}.${sufixo}`
    : "http://localhost:9000";

axios.defaults.baseURL = URL_BASE;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      let atualPath = appvue.$route.path;
      if (atualPath !== "/login") {
        appvue.showAlerta("Ops... Login expirado!");
        appvue.$store.commit("user/setTokenAuth", "");
        appvue.$store.commit("user/setAuthStatus", false);
        window.location.reload();
      }
    }

    return Promise.reject(error.response);
  }
);

axios.interceptors.request.use(
  config => {
    if (
      config.baseURL === URL_BASE &&
      !config.headers.Authorization &&
      appvue.$store.getters["user/getToken"]
    ) {
      let auth = appvue.$store.getters["user/getToken"];
      config.headers.Authorization = `${auth.tokenType} ${auth.accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

Vue.use(require("vue-moment"), {
  moment
});

Vue.use(require("vue-upload-component"), {
  vueUpload
})

Vue.use(VueTheMask);
Vue.use(DatetimePicker);


Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    asMoney(value) {
      return value
        ? value.toLocaleString("pt-br", { minimumFractionDigits: 2 })
        : value;
    },
    isMilgrau() {
      return isMilgrau();
    },
    isInfluencers() {
      return isInfluencers();
    },
    isIngressoLivre(){
      return isIngressoLivre();
    },
    isConca(){
      return isConca();
    },
    isBelaEntrada(){
      return isBelaEntrada();
    },
    isSBEventos(){
      return isSBEventos();
    },
    isElfort(){
      return isElfort();
    },
    isPorter(){
      return isPorter();
    },
    isPt(){
      return isPt();
    },
    isNxl(){
      return isNxl();
    },
    appProduto() {
      return appProduto();
    }
  }
});

var appvue = new Vue({
  router,
  store,
  vuetify,
  data: {
    type: "success",
    message: "",
    alert: false,
    icon: "",
    confirmDialog: false,
    callback: null,
    args: null,
    title: ""
  },
  methods: {
    showAlerta(msg) {
      this.type = "warning";
      this.message = msg;
      this.icon = "mdi-exclamation";
      this.show();
    },
    showInfo(msg) {
      this.type = "info";
      this.message = msg;
      this.icon = "mdi-information";
      this.show();
    },
    showSucesso(msg) {
      this.type = "success";
      this.message = msg;
      this.icon = "mdi-check-circle";
      this.show();
    },
    showErro(msg) {
      this.type = "error";
      this.message = msg;
      this.icon = "mdi-alert";
      this.show();
    },
    show() {
      this.alert = true;
      setTimeout(() => {
        this.reset();
      }, 5000);
    },
    reset() {
      this.type = "success";
      this.message = "";
      this.alert = false;
      this.icon = "";
    },
    showConfirmDialog(_msg, _title = "", _callback, _args = []) {
      this.message = _msg;
      this.title = _title;
      this.confirmDialog = true;
      this.callback = _callback;
      this.args = _args;
    }
  },
  beforeMount() {
    if (this.isInfluencers()) {
      Object.keys(colorsInfluencers).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsInfluencers[i])
      );
    } else if (this.isIngressoLivre()) {
      Object.keys(colorsIngressoLivre).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsIngressoLivre[i])
      );
    }
    else if (this.isConca()) {
      Object.keys(colorsIngressoLivre).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsConca[i])
      );
    }     else if (this.isBelaEntrada()) {
      Object.keys(colorsBela).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsBela[i])
      );
    }     else if (this.isSBEventos()) {
      Object.keys(colorsSBEventos).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsSBEventos[i])
      );
    }     else if (this.isElfort()) {
      Object.keys(colorsElfort).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsElfort[i])
      );
    }     else if (this.isPorter()) {
      Object.keys(colorsPorter).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsPorter[i])
      );
    }     else if (this.isPt()) {
      Object.keys(colorsPt).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsPt[i])
      );
    }     else if (this.isNxl()) {
      Object.keys(colorsNxl).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsNxl[i])
      );
    }else {
      Object.keys(colorsMilgrau).forEach(
        i => (this.$vuetify.theme.themes.light[i] = colorsMilgrau[i])
      );
    }
  },
  render: h => h(App)
}).$mount("#app");
